import React from "react"
import { graphql } from "gatsby"

import theme from "../themes/theme"
import * as EmailValidator from "email-validator"

import { PageLayout } from "../components/PageLayout"

import {
  // HeroGrid,
  // HeroSection,
  HeroHeadline,
  HeroSubHead,
  // HeroExtra,
  // HeroButton,
} from "../components/HeroSection"

import { FormButtonSecondary } from "../components/Buttons"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
} from "../components/SectionBlocks"

import { FaRegUser } from "react-icons/fa"
import { AiOutlineMail } from "react-icons/ai"
import { RiMessage2Line } from "react-icons/ri"

import SEO from "../components/seo"

const PageContact = ({ data, location }) => {
  return (
    <PageLayout>
      <SEO
        title="Contact Us"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
        description="Coaching professionals to look great, get stronger & play harder"
        url={location.origin + location.pathname}
      />
      <RowSection
        rowMargin="0%"
        backgroundUrl={data?.heroImage?.childImageSharp?.fixed}
        bgX="60%"
        bgY="0%"
        // overlay="rgba(255, 255, 255, 0.8)"
        // overlay="linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 10%)"
        // opacity="0.9">
      >
        <TwoColumnSection columnLeftWidth="50%" columnRightWidth="50%" sideMargin="5%">
          <TwoColumnSectionLeft>
            <div
              style={{
                borderRadius: "12px",
                height: "100%",
                marginTop: "5%",
                marginBottom: "5%",
                backgroundColor: theme.colors.primaryBlueNormal,
                marginLeft: "5%",
                marginRight: "5%",
                alignItems: "center",
                textAlign: "center",
              }}>
              <HeroHeadline>
                <h1
                  style={{
                    color: theme.colors.white,
                    textAlign: "center",
                    padding: "10px",
                    lineHeight: theme.spacings.large,
                  }}>
                  Contact Us
                </h1>
              </HeroHeadline>
              <HeroSubHead>
                <p
                  style={{
                    color: theme.colors.light1,
                    textAlign: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}>
                  Speak to one of our strength coaches and get your questions answered by an expert.
                </p>

                {/* <div className="kartra_optin_containereccbc87e4b5ce2fe28308fd9f2a7baf3"></div>
                <script src="https://app.kartra.com/optin/TcloDUQyO3a4"></script> */}

                <ScheduleForm />
                <p></p>
                <p
                  style={{
                    color: theme.colors.light3,
                    textAlign: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontSize: theme.spacings.mSmall,
                  }}>
                  Your information is completely safe. We do not sell your contact details or share
                  your personal information.
                </p>
                <p
                  style={{
                    color: theme.colors.light3,
                    textAlign: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    fontSize: theme.spacings.mSmall,
                  }}>
                  We also really hate spam too!
                </p>
              </HeroSubHead>
            </div>
          </TwoColumnSectionLeft>
          <TwoColumnSectionRight alignItems="center" justifyContent="center">
            <HeroHeadline>
              <h1
                style={{
                  color: theme.colors.white,
                  textAlign: "center",
                }}>
                Got a question?
              </h1>
            </HeroHeadline>
            <HeroSubHead>
              <p style={{ color: theme.colors.light1, textAlign: "center" }}>
                Not sure where to start? Have a previous injury you're concerned about? Tried a gym
                or trainer before and it didn't work out?
              </p>
              <p style={{ color: theme.colors.light1, textAlign: "center" }}>
                We know exactly how you feel! We are more than happy to spend time helping you get
                your questions answered.
              </p>
            </HeroSubHead>
          </TwoColumnSectionRight>
        </TwoColumnSection>
      </RowSection>
    </PageLayout>
  )
}

export default PageContact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }

    heroImage: file(absolutePath: { regex: "/mitch-kettlebell-row-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    socialImage: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-social-1200x628.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

class MessageComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onMessageChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userMessage">
            <RiMessage2Line color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <textarea
            // type="text area"
            name="custom_1"
            placeholder="What would you like to ask our coaches? The more information you can provide the better we can match the best coach to help ..."
            // value=""
            className="js_kartra_santitation"
            data-santitation-type="message"
            id="userMessage"
            aria-label="Message Name"
            rows="8"
            style={{
              width: "100%",
              // height: "10rem",
              border: this.props.messageBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}
          />
        </div>
      </div>
    )
  }
}

class UserNameComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserNameChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userName">
            <FaRegUser color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            name="first_name"
            placeholder="First name ..."
            // value=""
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="userName"
            aria-label="First Name"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.userBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}
          />
        </div>
      </div>
    )
  }
}

class UserEmailComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserEmailChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          border: "0px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userEmail">
            <AiOutlineMail color="white" fontSize="2.3rem" padding="5px" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            className="js_kartra_santitation"
            data-santitation-type="email"
            placeholder="Email address ..."
            name="email"
            id="userEmail"
            aria-label="Email Address"
            onChange={this.handleValueChange}
            // style={{ border: "1px solid white", padding: "5px" }}
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.emailBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
          />
        </div>
      </div>
    )
  }
}

class ScheduleForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleUserNameChange = this.handleUserNameChange.bind(this)
    this.handleUserEmailChange = this.handleUserEmailChange.bind(this)
    this.handleMessageChange = this.handleMessageChange.bind(this)
    this.state = {
      validName: false,
      userBorder: "none",
      validEmail: false,
      emailBorder: "none",
      validMessage: false,
      messageBorder: "none",
    }
  }

  handleUserNameChange(e) {
    if (e.length > 2) {
      this.setState({ validName: true, userBorder: "2px solid green" })
    } else {
      this.setState({ validName: false, userBorder: "2px solid red" })
    }
  }

  handleUserEmailChange(e) {
    if (EmailValidator.validate(e)) {
      this.setState({ validEmail: true, emailBorder: "2px solid green" })
    } else {
      this.setState({ validEmail: false, emailBorder: "2px solid red" })
    }
  }

  handleMessageChange(e) {
    if (e.length > 10) {
      this.setState({ validMessage: true, messageBorder: "2px solid green" })
    } else {
      this.setState({ validMessage: false, messageBorder: "2px solid red" })
    }
  }

  render() {
    return (
      <div>
        <form
          method="post"
          action="https://app.kartra.com/process/add_lead/kviLq3QF7YCp"
          target="_top"
          class="form_class_kviLq3QF7YCp optin_form_for_video js_kartra_trackable_object"
          data-kt-type="optin"
          data-kt-value="kviLq3QF7YCp"
          data-kt-owner="Brl61K8g"
          acceptCharset="UTF-8">
          <div>
            <UserNameComponent
              onUserNameChange={this.handleUserNameChange}
              userBorder={this.state.userBorder}
            />
            <UserEmailComponent
              onUserEmailChange={this.handleUserEmailChange}
              emailBorder={this.state.emailBorder}
            />
            <MessageComponent
              onMessageChange={this.handleMessageChange}
              messageBorder={this.state.messageBorder}
            />
          </div>
          <div style={{ marginLeft: "15px", marginRight: "15px" }}>
            <FormButtonSecondary
              type="submit"
              enabled={this.state.validName && this.state.validEmail && this.state.validMessage}>
              Speak with a Coach
            </FormButtonSecondary>
          </div>
        </form>
      </div>
    )
  }
}
